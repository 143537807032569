<template>
  <div class="home">
    <div class="header flex_center">
      <div class="inner">
        <div class="logo">
          <img src="../assets/image/logo.png" alt />
          <b>应约按摩</b>
        </div>
      </div>
    </div>
    <div class="banner flex_center">
      <div class="inner">
        <div class="btns">
          <div class="ios">
            <div class="code">
              <img src="../assets/image/pc/icon_ios_down_code.png" alt />
            </div>
          </div>
          <div class="android">
            <div class="code">
              <img src="../assets/image/pc/icon_android_down_code.png" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box flex_center b1">
      <div class="inner">
        <h1>轻松享受品质生活</h1>
        <p class="desc">
          <i></i>
          <b>ENGOY QUALITY LIFE EASILY</b>
          <i></i>
        </p>
        <div class="list">
          <div class="item">
            <img src="../assets/image/pc/icon_box_1_1.png" alt />
            <h5>一键到家</h5>
            <p>手机预约技师上门服务，足不出户体验美好生活。</p>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_box_1_2.png" alt />
            <h5>优选服务</h5>
            <p>平台把关严格筛选高价值、高标准的品牌服务。</p>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_box_1_3.png" alt />
            <h5>专业技师</h5>
            <p>平台认证技师手艺精湛，让你每一分钟都是享受。</p>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_box_1_4.png" alt />
            <h5>安全无忧</h5>
            <p>完整的线上交易流程加平台监管保障交易安全。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="box flex_center b2 box_shadow">
      <div class="inner">
        <h1>一键直约安心到家</h1>
        <p class="desc">
          <i></i>
          <b>ONE CLICK STRAIGHT HOME</b>
          <i></i>
        </p>
        <div class="list">
          <div class="item">
            <img src="../assets/image/pc/icon_box_2_1.png" alt />
            <div class="info">
              <h6>手机预约</h6>
              <p>通过手机下单预约项目和技师</p>
            </div>
            <span>1</span>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_box_2_2.png" alt />
            <div class="info">
              <h6>技师上门</h6>
              <p>技师接单上门服务</p>
            </div>
            <span>2</span>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_box_2_3.png" alt />
            <div class="info">
              <h6>享受按摩</h6>
              <p>在家享受品质服务</p>
            </div>
            <span>3</span>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_box_2_4.png" alt />
            <div class="info">
              <h6>评价服务</h6>
              <p>评价反馈技师服务</p>
            </div>
            <span>4</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box flex_center b3 box_shadow">
      <div class="inner">
        <h1>全品类按摩服务</h1>
        <p class="desc">
          <i></i>
          <b>ALL KINDS OF MASSAGE SERVICES</b>
          <i></i>
        </p>
        <div class="list">
          <div class="item">
            <img src="../assets/image/pc/icon_box_3_1.png" alt />
            <p>中医理疗</p>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_box_3_2.png" alt />
            <p>油压SPA</p>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_box_3_3.png" alt />
            <p>美容美体</p>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_box_3_4.png" alt />
            <p>养生足疗</p>
          </div>
        </div>
      </div>
    </div>
    <div class="comment flex_center">
      <div class="inner">
        <h1>用户喜欢我们的产品</h1>
        <p class="desc">
          <i></i>
          <b>USERS LIKE OUR PRODUCTS</b>
          <i></i>
        </p>
        <div class="list">
          <div class="item">
            <img src="../assets/image/pc/icon_comment_1.png" alt />
            <h6>王小雨</h6>
            <p>
              第一次体验，预约了一个中医推拿的项目，师傅按手法还不错，整体都比较满意。给个五星支持一下吧。
            </p>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_comment_2.png" alt />
            <h6>李密</h6>
            <p>
              技师力道把握的很好，超这个软件很棒，喜欢你们技师的手法，我要说我很喜欢吧我是朋友。
            </p>
          </div>
          <div class="item">
            <img src="../assets/image/pc/icon_comment_3.png" alt />
            <h6>邹艺</h6>
            <p>
              比了很多家了，比去实体店要划算，非常专业技师把每一个细节都做的非常专业微信还可以下单了很了不起。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="map flex_center">
      <div class="inner flex_center">
        <img src="../assets/image/pc/icon_map.png" alt />
        <div class="right flex_center">
          <div class="icon">
            <img src="../assets/image/pc/icon_map_i_1.png" alt />
            <img class="line" src="../assets/image/pc/icon_map_line.png" alt />
            <img src="../assets/image/pc/icon_map_i_2.png" alt />
            <img class="line" src="../assets/image/pc/icon_map_line.png" alt />
            <img src="../assets/image/pc/icon_map_i_3.png" alt />
          </div>
          <div class="data">
            <div class="item">
              <i>200+</i>
              <b>城市</b>
            </div>
            <div class="item">
              <i>20000+</i>
              <b>技师</b>
            </div>
            <div class="item">
              <i>2000万+</i>
              <b>用户</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="down flex_center">
      <div class="inner">
        <h1>立即下载体验</h1>
        <div class="codes">
          <div class="code">
            <img src="../assets/image/pc/icon_android_down_code.png" alt />
            <p>应约按摩APP</p>
          </div>
          <div class="code">
            <img src="../assets/image/pc/icon_miniApp_code.jpg" alt />
            <p>应约按摩小程序</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="contact_and_about_us flex_center">
        <div class="inner">
          <div class="contact">
            <h3>联系我们</h3>
            <p>客服：400-862-0602</p>
            <p>邮箱：wzh@angemo.com</p>
            <div class="store">
              <label>商家入驻</label>
              <label>400-862-0602</label>
            </div>
          </div>
          <div class="follow">
            <h3>关注我们</h3>
            <div class="icon">
              <div class="code">
                <img src="../assets/image/pc/icon_open_code.jpg" alt />
              </div>
            </div>
          </div>
          <div class="about">
            <h3>关于我们</h3>
            <p>
              应约按摩是一个高品质按摩平台，为商户提供获客渠道为用户提供按摩服务。支持到店按摩和上门按摩，连接本地优质商户，连接本地优质技师，以用户为中心，应约按摩始终致力于用户提供高品质的按摩服务体验。
            </p>
          </div>
        </div>
      </div>
      <div class="keep_on_record flex_center">
        <i> 深圳凸凸网络科技有限公司 &copy;yuean.co </i>
        <a href="https://beian.miit.gov.cn/">ICP备案号：粤ICP备16027090号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    var self = this;

    var winWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    var oHtml = document.getElementsByTagName("html")[0];
    oHtml.setAttribute(
      "style",
      `font-size: ${(100 * winWidth) / 1920}px !important;`
    );

    window.addEventListener("resize", function () {
      var winWidth =
        document.documentElement.offsetWidth || document.body.offsetWidth;
      oHtml.setAttribute(
        "style",
        `font-size: ${(100 * winWidth) / 1920}px !important;`
      );
    });
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-width: 1440px;
  .header {
    width: 100%;
    background: #fff;
    height: 1rem;

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 0.7rem;
        margin-right: 0.1rem;
      }
      b {
        font-size: 0.3rem;
        line-height: 0.7rem;
      }
    }
  }

  .banner {
    width: 100%;
    height: 8rem;
    background: url("../assets/image/pc/banner.png") no-repeat center center;
    background-size: cover;
    .inner {
      position: relative;
      height: 8rem;
      .btns {
        position: absolute;
        left: 0;
        bottom: 1.58rem;
        display: flex;

        > div {
          width: 1.8rem;
          height: 0.8rem;
          border-radius: 0.1rem;
          position: relative;

          &:hover {
            .code {
              display: block;
            }
          }

          .code {
            position: absolute;
            left: 0;
            bottom: 0.96rem;
            width: 1.8rem;
            height: 1.8rem;
            box-sizing: border-box;
            padding: 0.1rem;
            background: #fff;
            border-radius: 0.1rem;
            display: none;

            img {
              width: 100%;
            }
          }
        }

        .android {
          background: url("../assets/image/pc/icon_android_down_btn.png");
          background-size: 100% auto;
          margin-left: 0.76rem;
        }
        .ios {
          background: url("../assets/image/pc/icon_ios_down_btn.png");
          background-size: 100% auto;
        }
      }
    }
  }
  .box_shadow {
    box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.04);
  }
  .box {
    height: 8rem;

    h1 {
      color: #333333;
      font-size: 0.54rem;
      padding: 1rem 0 0.1rem;
      font-weight: normal;
      text-align: center;
    }

    .desc {
      color: #999999;
      font-size: 0.18rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.7rem;

      i {
        width: 0.24rem;
        height: 0.02rem;
        background: rgba(216, 216, 216, 1);
      }

      b {
        margin: 0 0.2rem;
      }
    }

    .inner {
      height: 8rem;
    }
  }

  .b1 {
    text-align: center;

    .list {
      display: flex;
      align-items: center;

      .item {
        width: 2.85rem;
        height: 4.1rem;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 0.22rem 0px rgba(0, 0, 0, 0.06);
        border-radius: 1px;
        margin-right: 0.2rem;
        overflow: hidden;

        img {
          width: 1.7rem;
          height: 1.7rem;
          display: block;
          margin: 0.3rem auto 0.4rem;
        }

        h5 {
          color: #333333;
          font-size: 0.3rem;
          font-weight: normal;
        }

        p {
          font-size: 0.18rem;
          padding: 0.28rem 0.16rem 0;
          color: #999;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .b2 {
    position: relative;
    z-index: 1;
    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 5.9rem;
        height: 2rem;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.04),
          0px 0px 0.1rem 0px rgba(0, 0, 0, 0.06);
        border-radius: 1px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding-left: 0.9rem;
        margin: 0 0.2rem 0.2rem 0;

        &:nth-child(2n) {
          margin-right: 0;
        }
        img {
          width: 1.5rem;
          margin-right: 0.2rem;
        }
        .info {
          h6 {
            color: #333333;
            font-size: 0.3rem;
          }
          p {
            color: #999999;
            font-size: 0.18rem;
            margin-top: 0.22rem;
          }
        }

        span {
          position: absolute;
          width: 0.4rem;
          height: 0.4rem;
          line-height: 0.4rem;
          text-align: center;
          background: rgba(221, 221, 221, 1);
          left: 0.2rem;
          top: 0.2rem;
          color: #fff;
          font-size: 0.26rem;
          border-radius: 50%;
        }
      }
    }
  }

  .b3 {
    position: relative;
    background: #fff;
    z-index: 2;
    .list {
      display: flex;
      .item {
        margin-right: 0.2rem;

        &:last-child {
          margin: 0;
        }
        img {
          width: 2.85rem;
          height: 2.85rem;
        }
        p {
          color: #333333;
          text-align: center;
          font-size: 0.3rem;
          margin-top: 0.3rem;
        }
      }
    }
  }

  .comment {
    width: 100%;
    height: 8.6rem;
    background: url("../assets/image/pc/icon_comment_back.png");
    background-size: 100%;

    .inner {
      height: 8.6rem;
      h1 {
        color: #fff;
        font-size: 0.54rem;
        padding: 1rem 0 0.1rem;
        font-weight: normal;
        text-align: center;
      }

      .desc {
        color: #fff;
        font-size: 0.18rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.7rem;

        i {
          width: 0.24rem;
          height: 0.02rem;
          background: rgba(216, 216, 216, 1);
        }

        b {
          margin: 0 0.2rem;
        }
      }

      .list {
        display: flex;
        align-items: flex-end;
        padding-bottom: 1.11rem;
        box-sizing: border-box;
        height: 5.81rem;

        .item {
          width: 3.88rem;
          height: 3.4rem;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.04);
          border-radius: 0.1rem;
          margin-right: 0.1rem;
          position: relative;
          box-sizing: border-box;
          padding: 0.24rem;
          font-size: 0.18rem;
          color: #999;

          &:last-child {
            margin-right: 0;
          }
          &:nth-child(2) {
            margin-bottom: 0.5rem;
          }

          img {
            width: 1.6rem;
            height: 1.6rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -0.8rem;
          }

          h6 {
            margin: 1rem 0 0.26rem;
            font-size: 0.3rem;
            color: #333;
          }
        }
      }
    }
  }

  .map {
    .inner {
      height: 8.6rem;
      > img {
        width: 8.34rem;
        margin-right: 1.11rem;
      }

      .right {
        .icon {
          margin-right: 0.4rem;

          img {
            display: block;
            margin: auto;
            width: 0.47rem;
          }

          .line {
            width: 0.09rem;
            margin: 0.1rem auto;
          }
        }
        .data {
          .item {
            margin-bottom: 0.91rem;

            &:last-child {
              margin: 0;
            }
            i,
            b {
              display: block;
            }
            i {
              color: #333333;
              font-size: 0.3rem;
              margin-bottom: 0.19rem;
            }

            b {
              font-size: 0.18rem;
              color: #999999;
            }
          }
        }
      }
    }
  }

  .down {
    box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.04);
    .inner {
      height: 4rem;
      text-align: center;
      color: #333333;
      h1 {
        font-size: 0.5rem;
        padding: 0.5rem 0 0.44rem;
        font-weight: normal;
      }
      .codes {
        display: flex;
        justify-content: center;

        .code {
          &:nth-child(1) {
            margin-right: 0.5rem;
          }
          img {
            width: 1.6rem;
            margin-bottom: 0.1rem;
          }
          p {
            font-size: 0.2rem;
          }
        }
      }
    }
  }

  .footer {
    height: 4.4rem;
    position: relative;

    .contact_and_about_us {
      width: 100%;
      height: 3.4rem;
      background: #666666;
      box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.04);
      color: #fff;
      .inner {
        display: flex;
        justify-content: space-between;
        height: 3.4rem;
        padding-top: 0.38rem;
        box-sizing: border-box;
        h3 {
          font-size: 0.3rem;
          font-weight: normal;
          margin-bottom: 0.2rem;
        }

        p {
          font-size: 0.18rem;
        }
        > div {
          text-align: left;
        }
        .contact {
          p {
            margin-bottom: 0.25rem;
            &:nth-child(2) {
              margin-bottom: 0.2rem;
            }
          }

          .store {
            width: 2.1rem;
            height: 0.9rem;
            background: rgba(255, 255, 255, 1);
            border-radius: 0.04rem;
            color: #333333;
            font-size: 0.24rem;
            box-sizing: border-box;
            padding-left: 0.2rem;
            padding-top: 0.1rem;
            label {
              display: block;
              margin-bottom: 0.1rem;
            }
          }
        }

        .follow {
          .icon {
            width: 0.6rem;
            height: 0.6rem;
            background: url("../assets/image/pc/icon_follow_wechat.png");
            background-size: 100% auto;
            border-radius: 0.04rem;
            position: relative;

            &:hover {
              .code {
                display: block;
              }
            }
            .code {
              width: 1.4rem;
              height: 1.4rem;
              border-radius: 0.04rem;
              overflow: hidden;
              position: absolute;
              top: 0.8rem;
              left: 0;
              display: none;
              img {
                width: 1.4rem;
                height: 1.4rem;
              }
            }
          }
        }
        .about {
          width: 2.7rem;
        }
      }
    }
    .keep_on_record {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1rem;
      width: 100%;
      background: rgba(51, 51, 51, 1);
      border-radius: 1px;
      text-align: center;
      line-height: 1rem;
      font-size: 0.18rem;
      color: #e8eff7;

      a {
        color: #e8eff7;
      }

      i,
      a {
        margin-right: 0.6rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
